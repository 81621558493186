<template lang="pug">
include ../../../configs/template
div.container-fluid
  div.row
    div.col-12.col-sm-12
      div.text-uppercase {{ $t('viewReportRecord') }}
  div.row
    div.col-12.col-sm-12
      b {{ $t('firstForm') }}:
    div.col-12.ml-4
      div.row
        div.col-12.col-sm-12
          b {{ $t('price') }} {{ $t('secondForm') }} :
          span {{ row.price_form1 }}
      div.row
        div.col-12.col-sm-12
          b {{ $t('total') }} {{ $t('secondForm') }} :
          span {{ row.sum_to_distribution_f1 }}
      div.row
        div.col-12.col-sm-12
          b {{ $t('profit') }} :
          span {{ row.profit }}

  div.row
    div.col-12.col-sm-12
      b {{ $t('secondForm') }}:
    div.col-12.ml-4
      div.row
        div.col-12.col-sm-12
          b {{ $t('price') }} {{ $t('secondForm') }} :
          span {{ row.price_form1 }}
      div.row
        div.col-12.col-sm-12
          b {{ $t('total') }} {{ $t('secondForm') }} :
          span {{ row.sum_to_distribution_f2 }}
      div.row
        div.col-12.col-sm-12
          b {{ $t('profit') }} :
          span {{ row.profit }}

</template>
<script>
export default {
  name: 'InfoFinance',
  props: { row: { type: Object, default: () => ({}) } },
  mounted () {
    this.transferToString('sum_to_distribution_f1')
    this.transferToString('sum_to_distribution_f2')
  },
  methods: {
    transferToString (distributionLabel) {
      let distributionSum = this.row[distributionLabel]
      let distribution = []
      for (let d in distributionSum) {
        let label = this.setLabelName(d)
        distribution.push([label, distributionSum[d]].join(': '))
      }
      this.row[distributionLabel] = distribution.join(', ')
    },
    setLabelName (prop) {
      switch (prop) {
        case 'to_td': return this.$t('toTD')
        case 'to_sqc': return this.$t('toSQC')
        case 'to_qd': return this.$t('toQD')
        case 'to_sc': return this.$t('toSC')
        case 'to_agent': return this.$t('toCrewingManager')
        case 'to_mrc': return this.$t('toMRC')
        case 'to_medical': return this.$t('toMedical')
        case 'to_cec': return this.$t('toCEC')
        case 'to_portal': return this.$t('toPortal')
        case 'to_eti': return this.$t('toETI')
        case 'amount': return this.$t('all')
      }
    }
  }
}
</script>
